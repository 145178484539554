import Vue from 'vue'
import App from './App.vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'


const ajax = axios.create({
  baseURL: (process.env.VUE_APP_API_BASE !== undefined) ? process.env.VUE_APP_API_BASE : 'https://bproi.lndo.site/api/'
})

Vue.config.productionTip = false
Vue.prototype.$axios = ajax
Vue.use(VueCompositionAPI)

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

new Vue({

  render: h => h(App),
}).$mount('#app')
