<template>
  <div id="app">
    <RoiCalculator :contact="contact"></RoiCalculator>
  </div>
</template>

<script>
import RoiCalculator from './components/RoiCalculator.vue';
export default {
  name: 'App',
  components: {
    RoiCalculator
  },
  beforeMount () {
    this.contact = parseInt(document.querySelector("#contact").dataset.contactId)
  }
}
</script>
