<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="c-stepper">
                    <div class="c-stepper__step-wrap" v-for="(step, index) in form" :key="index"
                         @click="goToStep(index)">
                        <span v-if="index < currentStep" class="c-stepper__step is-completed">
                            <span class="sr-only">{{index + 1}}</span>
                        </span>
                        <div v-if="index === currentStep"
                             class="c-stepper__current-step c-icon-shield c-icon-shield--medium  c-icon-shield--number">
                            <div class="c-icon-shield__inner">
                                <span class="u-text-normal">{{index + 1}}</span>
                            </div>
                        </div>
                        <span v-if="index > currentStep" class="c-stepper__step">
                            <span class="sr-only">{{index + 1}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <form id="signup-form" @submit.prevent="processForm">
                    <div class="c-squiggle-bg">
                        <svg class="c-squiggle-bg__svg" :class="`step-${currentStep}`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 830.7 2107.8">
                            <path fill="none" stroke="#fcd435" stroke-width="15" d="M41.2 4c-19 30.2 47.5 120 190 85.2 159.1-38.8 454.7-38 454.7 127.3 0 61.5-57.2 181.1-303.9 194.2C35.2 429-131.7 682 149.7 829.7 372 938.4 718.1 958.6 609.5 1203.6c-101.1 197.1-646.7 116.3-565.9 428 87.2 238.9 372.5 162.5 605 221.6s165.8 252.2 165.8 252.2"/>
                        </svg>

                    </div>
                    <transition-group name="slide-fade">
                        <div v-for="(step, step_index) in form" :key="step.name" class="c-roi-form-group"
                             :class="{'is-active' : step_index === currentStep}"
                             :id="`step-${step_index}`"
                             v-show="step_index <= lastVisibleStep"
                        >
                            <label class="c-roi-form-group__label" :for="step.name">
                                {{step.question}}
                                <span class="c-roi-form-group__tooltip c-tooltip">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" fill="none"
                                     viewBox="0 0 9 15">
                                    <title>Tooltip</title>
                                    <path fill="#1C1C1C"
                                          d="M5.36 13.7c-.307.28-.687.42-1.14.42-.453 0-.833-.14-1.14-.42a1.468 1.468 0 01-.44-1.08c0-.44.147-.807.44-1.1.307-.293.687-.44 1.14-.44.453 0 .833.147 1.14.44.307.293.46.66.46 1.1 0 .427-.153.787-.46 1.08zM4.32.28c1.293 0 2.307.333 3.04 1 .733.667 1.1 1.513 1.1 2.54a3.32 3.32 0 01-.48 1.74c-.307.533-.647.967-1.02 1.3-.373.32-.72.713-1.04 1.18-.307.467-.46.953-.46 1.46H2.98c0-.56.1-1.073.3-1.54.213-.48.467-.887.76-1.22.293-.333.587-.647.88-.94.307-.293.56-.6.76-.92.213-.333.32-.667.32-1 0-.4-.133-.727-.4-.98-.267-.267-.64-.4-1.12-.4-.507 0-.893.127-1.16.38-.267.24-.4.54-.4.9L.58 3.54c0-.933.313-1.707.94-2.32.627-.627 1.56-.94 2.8-.94z"/>
                                </svg>
                                <small class="c-tooltip__content">{{step.tooltip}}</small>
                            </span>
                            </label>
                            <small class="c-roi-form-group__content"
                                   v-html="`<strong>Did you Know?</strong> ${step.content}`">
                            </small>
                            <div class="c-roi-form-group__control">

                                <div v-if="step.input_type === 'number'" class="c-input c-input-number">
                                    <input class="c-input__control c-input__control--large" :id="step.name"
                                           aria-label=""
                                           :name="step.name"
                                           type="number"
                                           :placeholder="step.placeholder"
                                           v-model="$data[step.name]"
                                           @change="handleInputChange(step_index)">
                                </div>

                                <div class="d-flex flex-wrap" v-if="step.input_type === 'range'">

                                    <div class="flex-grow-1 w-100">
                                        <Slider v-model="$data[step.name]" :min="step.min_value" :max="step.max_value" :step="step.interval"
                                                :format="step.format"
                                                @change="handleInputChange(step_index)"
                                        />
                                    </div>
                                    <div class="d-flex justify-content-between w-100 mt-7">
                                        <span class="u-text-medium-grey u-header-xsmall">
                                            <span v-if="step.prefix">{{step.min_value | currency }}</span>
                                            <span v-if="step.suffix">{{step.min_value}}{{step.suffix}}</span>
                                        </span>
                                        <span class="u-text-medium-grey u-header-xsmall">
                                            <span v-if="step.prefix">{{step.max_value | currency }}</span>
                                            <span v-if="step.suffix">{{step.max_value}}{{step.suffix}} <span v-if="step.extra_suffix">{{step.extra_suffix}}</span></span>
                                        </span>
                                    </div>

                                </div>

                                <div v-if="step.input_type === 'radio'">
                                    <ul class="row flex-wrap list-unstyled">
                                        <li class="col-4 mb-5" v-for="(option, option_index) in step.radio_options"
                                            :key="option.value">
                                            <input class="c-radio" type="radio" :name="step.name" :id="'radio-' + option_index"
                                                   :value="option.value"
                                                   v-model="$data[step.name]"
                                                   @change="handleInputChange(step_index)"
                                            >
                                            <label class="u-header-small"
                                                   :for="'radio-' + option_index">{{option.label}}</label>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="step.input_type === 'select'" class="c-select">
                                    <i class="c-select__icon c-icon c-icon--medium">expand_more</i>
                                    <select class="c-select__control c-select__control--large " :id="step.name"
                                            v-model="$data[step.name]"
                                            @change="handleInputChange(step_index)">
                                        <option class="c-select__option " v-for="option in step.select_options"
                                                :key="option.value"
                                                :value="option.value"
                                        >
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </transition-group>

                      <transition name="slide-fade">
                        <div  v-show="!submitEnabled" class="text-center">
                            <button @click.prevent="goToNextStep(currentStep)" class="c-button c-button--medium-blue mb-8"
                                    type="submit">
                                Next
                            </button>
                        </div>
                    </transition>


                    <transition name="slide-fade">
                        <div  v-show="submitEnabled" class="text-center">
                            <button @click="calculate()" :id="`step-${form.length + 1}`" class="c-button c-button--yellow mb-8"
                                    type="submit">
                                Calculate
                            </button>
                            <div id="hs_cta">
                                <span class="hs-cta-wrapper" id="hs-cta-wrapper-f4783306-e83f-4b56-be6b-fc614f230427">
                                <span class="hs-cta-node hs-cta-f4783306-e83f-4b56-be6b-fc614f230427" id="hs-cta-f4783306-e83f-4b56-be6b-fc614f230427">
                                <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                <a ref="hs_cta" href="https://cta-redirect.hubspot.com/cta/redirect/7794181/f4783306-e83f-4b56-be6b-fc614f230427" >
                                <img class="hs-cta-img" id="hs-cta-img-f4783306-e83f-4b56-be6b-fc614f230427" style="border-width:0px;" src="https://no-cache.hubspot.com/cta/default/7794181/f4783306-e83f-4b56-be6b-fc614f230427.png"  alt="Calculate"/></a>
                                </span>
                                </span>
                            </div>
                        </div>
                    </transition>

                    <div class="results ">
                        <h2>Results (TESTING ONLY)</h2>
                        <h3>Total Value</h3>
Employee Total Annual Value	        : $ {{ Math.round(	employee_total_annual_value ).toFixed(2) }}<br>
Employer Total Value	        : $ {{ Math.round(	employer_total_value ).toFixed(2) }}<br>
<br><br>
                       <h3>Employer Savings</h3>
Employer Healthcare Savings	        : $ {{ Math.round(	employer_healthcare_savings ).toFixed(2) }}<br>
Employer Payroll Tax Savings	        : $ {{ Math.round(	employer_payroll_tax_savings ).toFixed(2) }}<br>
Employer Turnover Savings	        : $ {{ Math.round(	employer_turnover_savings ).toFixed(2) }}<br>
Employer Absenteeism Savings	        : $ {{ Math.round(	employer_absenteeism_savings ).toFixed(2) }}<br>
Employer Delayed Retirement Savings	        : $ {{ Math.round(	employer_delayed_retirement_savings ).toFixed(2) }}<br>
<br>
       <h3>Employee Savings</h3>
Employee Healthcare Savings	        : $ {{ Math.round(	employee_healthcare_savings ).toFixed(2) }}<br>
Employee Hsa Tax Savings	        : $ {{ Math.round(	employee_hsa_tax_savings ).toFixed(2) }}<br>
Employee Financial Advice Value	        : $ {{ Math.round(	employee_financial_advice_value ).toFixed(2) }}<br>
Employee Paid Time Off Value	        : $ {{ Math.round(	employee_paid_time_off_value ).toFixed(2) }}<br>
Employee Retirement Tax Savings	        : $ {{ Math.round(	employee_retirement_tax_savings ).toFixed(2) }}<br>
Employee Retirement Savings	        : $ {{ Math.round(	employee_retirement_savings ).toFixed(2) }}<br>
Behaviour Change	        : $ {{ Math.round(	behaviour_change ).toFixed(2) }}<br>
Retirement Contribution Increase	        : $ {{ Math.round(	retirement_contribution_increase ).toFixed(2) }}<br>
Hsa Contribution Increase	        : $ {{ Math.round(	hsa_contribution_increase ).toFixed(2) }}<br>
<br>

<br><br><br><br>

                    </div>

                </form>
            </div>
        </div>

    </div>
</template>

<script>
  import Slider from '@vueform/slider/dist/slider.vue2.js';
  import VueScrollTo from 'vue-scrollto';


  const dollar_format = {'prefix': '$', 'decimals': 0, 'thousand': ','};
  const percent_format = {'prefix': '', 'decimals': 0, 'thousand': ',', 'suffix': '%'};
  const age_format = {'suffix': ' years old', 'decimals': 0};

  export default {
    name: 'RoiCalculator',
    components: {
      Slider,
    },
    props: {
      contact: Number
    },
    data: function () {
      return {
        // stepper
        currentStep: 0,
        lastVisibleStep: 0,
        selectedStep: 0,
        submitEnabled: false,
        // form
        form: [
          {
            name: 'employee_count',
            question: 'How many employees does your organization have in the U.S.?',
            content: 'Nearly half (47%) of Americans are employed by companies with more than 1,000 employees (<a href="https://www.census.gov/programs-surveys/susb/data/custom-tabulations.html" target="_blank" rel="noopener">U.S. Census Bureau</a>).',
            tooltip: 'Enter the number of full-time employees in the United States who are eligible for your benefits package. Do not include interns, contractors, employees without access to benefits, or employees outside of the U.S.',
            input_type: 'number',
            placeholder: 'Eg. 5000',
          },
          {
            name: 'average_salary',
            question: 'What is the average annual salary per U.S. employee at your organization?',
            content: 'Middle-income U.S. households earn between $48,500 to $145,500 annually for a family of 3 (<a href="https://www.pewresearch.org/fact-tank/2020/07/23/are-you-in-the-american-middle-class/" target="_blank" rel="noopener">Pew Research</a>).',
            tooltip: 'Select the closest number to the annual salary before taxes of the average U.S. employee in your organization.',
            input_type: 'range',
            min_value: 30000,
            max_value: 200000,
            interval: 10000,
            format: dollar_format,
            prefix: '$',
          },
        //   {
        //     name: 'average_401k',
        //     question: 'What is the average participant balance for your company\'s 401(k) plan?',
        //     content: 'The average employee with access to a 401(k) contributes 8.9% of their paycheck towards retirement (Fidelity).',
        //     tooltip: 'Select the closest number to how much an average employee has in your company’s 401(k) plan or equivalent. This number may include matching contributions and rollovers if applicable.',
        //     input_type: 'range',
        //     min_value: 0,
        //     max_value: 200000,
        //     interval: 10000,
        //     format: dollar_format,
        //     prefix: '$',
        //   },
        //   {
        //     name: 'average_vested',
        //     question: 'What is the average combined value of RSUs vesting and ESPP contributions per employee each year at your company?',
        //     content: 'Restricted Stock Units (RSUs) have become a popular way for large companies to incentivize employees.',
        //     tooltip: 'Select the approximate value of Restricted Stock Units (RSUs) that vest per employee per year, plus Employee Stock Purchase Plan (ESPP) purchased per employee per year. For example, if the average employee has $15,000 of RSUs vesting annually plus $10,000 ESPP purchased, select $25,000. If your organization does not have an ESPP program, select the closest number to the annual vested RSU value per employee. If your organization has neither, select $0.',
        //     input_type: 'range',
        //     min_value: 0,
        //     max_value: 500000,
        //     interval: 25000,
        //     format: dollar_format,
        //     prefix: '$',
        //   },
          {
            name: 'annual_voluntary_turnover',
            question: 'What is your organization\'s annual voluntary turnover rate?',
            content: 'Each voluntary turnover costs organizations at least 16% of an employee’s salary (<a href="https://www.asppa-net.org/news/browse-topics/measuring-roi-financial-wellness" target="_blank" rel="noopener">Questis</a>).',
            tooltip: 'The number of employees who voluntarily leave their job per year, expressed as a percentage of all employees.',
            input_type: 'range',
            min_value: 1,
            max_value: 10,
            interval: 1,
            suffix: '%',
            extra_suffix: ' or more',
            format: percent_format,
          },
          {
            name: 'average_age',
            question: 'What is the average age of your employees?',
            content: 'The typical American worker in 2019 was 42 years old, up from 39 in 1999 (<a href="https://www.bls.gov/cps/demographics.htm" target="_blank" rel="noopener">Bureau of Labor Statistics</a> ).',
            tooltip: 'Choose the number that is closest to the average employee age for your organization.',

            input_type: 'range',
            min_value: 25,
            max_value: 60,
            interval: 5,
            format: age_format,
            suffix: ' years'
          },
          {
            name: 'age_mix',
            question: 'What is the mix of employees born before 1964 (i.e. age 56 or older) at your organization?',
            content: 'Millennials overtook Gen-Xers in 2016 as the nation’s largest generation in the workforce (<a href="https://www.pewresearch.org/fact-tank/2018/04/11/millennials-largest-generation-us-labor-force/#:~:text=Millennials%20are%20the%20largest%20generation%20in%20the%20U.S.%20labor%20force&text=More%20than%20one%2Din%2Dthree,of%20U.S.%20Census%20Bureau%20data" target="_blank" rel="noopener"> Pew Research</a>).',
            tooltip: 'Approximate mix of employees born before 1964 in your organization.',
            input_type: 'select',
            select_options: [
              {
                value: 'low',
                label: 'Low (<20%)'
              },
              {
                value: 'medium',
                label: 'Medium (20-40%)',
              },
              {
                value: 'high',
                label: 'High (>40%)'
              },
            ]
          },
        ],

        // data
        employee_count: 5000,
        average_salary: 80000,
        average_401k: 50000,
        average_vested: 50000,
        annual_voluntary_turnover: 2,
        average_age: 35,
        age_mix: 'medium',

        // Employer subsection savings
        employer_healthcare_savings: 0,
        employer_payroll_tax_savings: 0,
        employer_turnover_savings: 0,
        employer_absenteeism_savings: 0,
        employer_delayed_retirement_savings: 0,

        // Employee subsection savings
        employee_healthcare_savings: 0,
        employee_hsa_tax_savings: 0,
        employee_financial_advice_value: 0,
        employee_paid_time_off_value: 0,
        employee_retirement_tax_savings: 0,
        employee_retirement_savings: 0,
        behaviour_change: 'Low',
        retirement_contribution_increase: 0,
        hsa_contribution_increase: 0,

        // Total value
        employee_total_annual_value: 0,
        employer_total_value: 0,

      }

    },
    watch: {
      currentStep: function () {
        if (this.currentStep >= this.lastVisibleStep) {
          return this.lastVisibleStep = this.currentStep;
        }
      }
    },
    methods: {
      processForm: function (calculate = false) {
        const data = {
          'id': this.contact,
          'employee_count': this.employee_count,
          'average_salary': this.average_salary,
          'average_401k': this.average_401k,
          'average_vested': this.average_vested,
          'annual_voluntary_turnover': this.annual_voluntary_turnover,
          'average_age': this.average_age,
          'age_mix': this.age_mix,
          'step': this.currentStep + 1,
          'total_steps': Object.keys(this.form).length,
           'calculate': calculate
        };

        this.$axios
          .post(`/submission`, data)
          .then(response => {

              if(calculate) {

                let url = document.getElementById("cta_button_7794181_efd4cf0e-4252-4f87-8190-15978ab6663f").href;
                if(!url) {
                    url = process.env.VUE_APP_RESULTS_PAGE + '?cid=' + this.id;
                }

                var expireIn = new Date().getTime() + 180 * 60 * 1000;
                this.$cookie.set("tfw_finished", true, expireIn)

                window.location.href = url;

            } else {
                Object.entries(response.data.data).forEach(entry => {
                const [key, value] = entry;
                this[key] = value;
                });
            }
          })
      },
      setActiveStep(index) {
        this.currentStep = index;
      },
      scrollToStep(index) {

        let options = {
          offset: 0,
          easing: 'linear',
        };
        let elmnt = document.getElementById("signup-form");
        console.log(elmnt.offsetHeight);
        if (window.innerWidth > 991) {
          options.offset = -200;
        } else {
          options.offset = -100;
        }

        VueScrollTo.scrollTo(`#step-${index}`, options);

      },
      goToStep(index) {
        this.setActiveStep(index);
        this.$nextTick(function() {
          this.scrollToStep(index);
        });
      },
      goToNextStep(index) {

        let nextStep = index + 1;

        if(nextStep === this.form.length - 1) {
          this.submitEnabled = true;
        }

        this.processForm();
        this.setActiveStep(nextStep);
        this.$nextTick(function() {
          this.scrollToStep(nextStep);
        });
      },
      handleInputChange(index) {

         if(index <= this.lastVisibleStep && !this.submitEnabled) {
           this.goToNextStep(index);
         } else {
           this.processForm();
         }
      },
      calculate() {
        this.processForm(true)
      },
    },
    filters:{
      currency(value) {
        return new Intl.NumberFormat("en-US",
          { maximumFractionDigits: 0, minimumFractionDigits: 0, style: "currency", currency: "USD" }).format(value);
      }
    },
  mounted() {

    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)
    let no_redirect = false
    no_redirect = params.get("noredirect")

    if(this.$props.contact) {
        let expireIn = new Date().getTime() + 180 * 60 * 1000;
        this.$cookie.set("tfw_id", this.$props.contact, expireIn)

    } else if(!no_redirect) {
        console.log(this.$props.contact)
        window.location.href = process.env.VUE_APP_SIGNUP_PAGE
    }

    let finished = this.$cookie.get("tfw_finished")
    if(finished && !no_redirect) {
        window.location.href = process.env.VUE_APP_RESULTS_PAGE + '?cid=' + this.$props.contact
    }

    this.$axios
    .get(`/submission/${this.$props.contact}`)
    .then(response => {
        Object.entries(response.data.data).forEach(entry => {
        const [key, value] = entry;
        this[key] = value;
        });
    })

  }
}
</script>

<style lang="scss">

    // TESTING ONLY
    .results {
        // position: fixed;
        display: block;
        top: 0;
        right: 0;
        padding: 5px;
        border: solid #ddd 1px;
        background: #fff;
    }



    $dark-blue: #0D2032;
    $stepper-grey: #E8EAEC;


    $screen-sm-up: "screen and (min-width: 576px)";
    $screen-md-up: "screen and (min-width: 768px)";
    $screen-lg-up: "screen and (min-width: 992px)";
    $screen-xl-up: "screen and (min-width: 1280px)";

    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        border-radius: 8px;
        color: var(--input-placeholder-color);
    }

    .c-input-number{
        border-radius: 8px;
        background: #93C3E3;
        padding: 20px;
    }

    input[type="text"] {

    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .c-input__control,
    .c-select__control {
        &--large {
            height: 60px !important;
            line-height: 60px !important;
            font-size: var(--header-font-size-small) !important;
        }
    }

    .c-squiggle-bg {
        display: none;
        position: absolute;
        top: -104px;
        z-index: -1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @media #{$screen-lg-up} {
            display: block;
        }
        &__svg {
            width: 60%;
            stroke-dasharray: 7000;
            stroke-dashoffset: 6300;
            &.step-1
            {
                animation: dash-1 2s linear forwards;
            }
            &.step-2 {
                animation: dash-2 2s linear forwards;
            }
            &.step-3 {
                animation: dash-3 2s linear forwards;
            }
            &.step-4 {
                animation: dash-4 2s linear forwards;
            }

        }

    }


    @keyframes dash-1 {
        from {
            stroke-dashoffset: 6300;
        }
        to {
            stroke-dashoffset: 5400;
        }
    }

    @keyframes dash-2 {
        from {
            stroke-dashoffset: 5400;
        }
        to {
            stroke-dashoffset: 4700;
        }
    }

    @keyframes dash-3 {
        from {
            stroke-dashoffset: 4700;
        }
        to {
            stroke-dashoffset: 4000;
        }
    }

    @keyframes dash-4 {
        from {
            stroke-dashoffset: 4000;
        }
        to {
            stroke-dashoffset: 2760;
        }
    }



    .c-stepper {
        $this: &;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        padding: 40px 0 60px 0;
        margin-bottom: 75px;
        background-color: #ffffff;
        @media #{$screen-xl-up} {
            width: 55%;
        }
        &::before {
            content: '';
            display: block;
            height: 3px;
            background-color: $stepper-grey;
            position: absolute;
            top: calc(50% - 10px);
            transform: translateY(-50%);
            z-index: 0;
            left: 10px;
            right: 10px;
        }
        &__step-wrap {
            &:first-child {
                #{$this}__current-step {
                    &::before {
                        content: none;
                    }
                }
            }
        }
        &__current-step {
            position: relative;
            &::before {
                content: '';
                display: block;
                width: 10000px;
                height: 4px;
                background-color: $dark-blue;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &__step {
            display: block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            flex: 0 0 auto;
            background-color: $stepper-grey;
            &.is-completed {
                background-color: $dark-blue;
            }
        }
    }

    .c-tooltip {
        $this: &;
        position: absolute;
        right: -46px;
        top: -28px;
        cursor: pointer;
        background-color: #F6D458;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        &:hover {
            #{$this}__content {
                opacity: 1;
            }
        }
        &__content {
            opacity: 0;
            padding: 10px 15px;
            position: absolute;
            right: 50px;
            background-color: #979797;
            border-radius: 3px;
            color: white;
            width: 250px;
            line-height: 1.2;
            pointer-events: none;
            @media #{$screen-xl-up} {
                right: auto;
                left: 66px;
                width: 350px;
                line-height: 1.3;
            }
            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 0px;
                right: 6px;
                top: 50%;
                border: 10px solid transparent;
                border-left: 10px solid #979797;
                border-right: 0;
                transform: translate(calc(100% + 5px), -50%);
                @media #{$screen-xl-up} {
                    // right-arrow
                    left: 6px;
                    right: auto;
                    border-left: 0;
                    border-right: 15px solid #979797;
                    transform: translate(calc(-100% - 5px), -50%);
                }
            }
        }
    }

    .c-roi-form-group {
        $this: &;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 115px;
        &.is-active {
            #{$this}__label {
                background-color: #1E3145;
            }
        }
        &__control {
            flex: 1 1 100%;
            max-width: 100%;
            padding: 0 15px;
            @media #{$screen-xl-up} {
                flex: 1 1 52%;
                max-width: 52%;
                margin-left: auto;
                padding: 0;
            }
        }
        &__label {
            position: relative;
            padding: 20px 25px;
            font-size: var(--header-font-size-small);
            line-height: 32px;
            background-color: #979797;
            border-radius: 8px;
            color: #ffffff;
            flex: 1 1 100%;
            max-width: 100%;
            margin-bottom: 15px;
            @media #{$screen-xl-up} {
                flex: 1 1 50%;
                max-width: 50%;
                margin-right: 10%;
                margin-bottom: 30px;
            }
        }
        &__tooltip {
            position: absolute;
            right: -10px;
            top: -18px;
            @media #{$screen-lg-up} {
                right: -46px;
                top: -28px;
            }
        }
        &__content {
            flex: 1 1 100%;
            max-width: 100%;
            margin-bottom: 10px;
            padding: 0 15px;
            @media #{$screen-xl-up} {
                flex: 1 0 35%;
                max-width: 35%;
                margin-left: 85px;
                margin-right: 100px;
                padding: 0;
            }
        }
    }

    .c-select {
        padding: 10px;
        border-radius: 8px;
        background-color: #93C3E3;
        @media #{$screen-lg-up} {
            padding: 20px;
        }
    }

    $checkbox-marker-size: 20px;
    $radio-marker-size: 30px;
    $checkbox-radius: 50%;
    $checkbox-radio-size: 30px;

    $checkbox-radio-gap: 15px;
    $checkbox-radio-border-width: 0;
    $checkbox-radio-color-bg: red;

    .c-radio {
        + label {
            &::before {
                background-size: $radio-marker-size;
                border-radius: 50%; // radio button radius
            }
        }
    }

    .c-radio {
        + label {
            &::before {
                background-size: $checkbox-marker-size;
                border-radius: $checkbox-radius; // checkbox button radius
            }
        }
    }

    .c-radio,
    .c-checkbox {
        $this: &;
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        pointer-events: none;
        opacity: 0;
        &:checked + label {
            &::before {
                background-color: #5195CC;
                border-color: #5195CC;
                box-shadow: none;
                opacity: 1;
            }
        }
        + label {
            display: inline-flex;
            align-items: center;
            line-height: $checkbox-radio-size;
            cursor: pointer;
            user-select: none;
            &::before {
                display: inline-block;
                flex-shrink: 0;
                width: $checkbox-radio-size;
                height: $checkbox-radio-size;
                margin-right: $checkbox-radio-gap;
                vertical-align: middle; // fallback
                content: '';
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                border-color: #DCE9F3;
                border-style: solid;
                border-width: 1px;
                transition: transform .2s, opacity .2s;
            }
        }
    }

    #slider {
        height: 9px;
        margin-top: 20px;
        .slider-base {
            border-radius: 90px;
        }
        .slider-tooltip {
            background-color: white;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            padding: 0 15px;
            width: auto;
            height: 43px;
            line-height: 43px;
            font-size: var(--header-font-size-xsmall);
            font-weight: 300;
            color: #0D2032;
            border: none;
            top: 67px;
            @media #{$screen-md-up} {
                width: 190px;
                font-size: var(--header-font-size-small);
            }
            &::before {
                content: none;
            }
        }
        .slider-handle {
            width: 11px;
            height: 44px;
            border-radius: 10px;
            background: #84C3E7;
            top: 0;
            transform: translateY(-45%);
            box-shadow: none;
        }
        .slider-connect {
            background-color: #1E3145;
        }
    }

    // vue transition

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all 1s ease;
    }
    .slide-fade-enter
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(-10px);
        opacity: 0;
    }

    #hs_cta{display:none!important;}

</style>
<style src="@vueform/slider/themes/default.css"></style>


